import React from "react";
import Header from "../components/Header";
import Quote from "../components/Quote";
import Layout, { PageLayout } from "../components/Layout";
import { graphql } from "gatsby";
import {
  MaybeStickyTitle,
  Title,
  TitleDescription,
} from "../components/Titles";
import Nest from "../components/Nest";
import { ArticleItem, ArticleList } from "../components/Articles";

class ThoughtsPage extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <PageLayout slug={this.props.location.pathname}>
        <Nest>
          <Header />
          <Quote
            content="Seek ye out of the best books words of wisdom; seek learning, even by study and also by faith."
            source="D&C 88:118"
          />
          <Layout
            left={
              <MaybeStickyTitle>
                <Title>
                  FAITH, PHILOSOPHY &amp; SCIENCE FROM THE ARMCHAIR.
                </Title>
                <TitleDescription>
                  In which I postulate as an amateur on all things related to
                  God, Science, Faith, Mental Illness & Religion.
                </TitleDescription>
              </MaybeStickyTitle>
            }
            right={
              <ArticleList>
                {data.allMdx.edges.map(({ node }) => (
                  <ArticleItem
                    key={node.id}
                    title={node.frontmatter.title}
                    timeToRead={node.timeToRead}
                    description={node.frontmatter.excerpt}
                    slug={node.fields.slug}
                  />
                ))}
              </ArticleList>
            }
          />
        </Nest>
      </PageLayout>
    );
  }
}

export const query = graphql`
  query Thoughts {
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/thoughts/*.mdx" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            excerpt
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default ThoughtsPage;
